import React from "react";
import singer from "../../../../img/singer-icon.png";
import piano from "../../../../img/piano-icon.png";
import strings from "../../../../img/strings-icon.png";
import tobias from "../../../../img/Tobias.png";
import kalle from "../../../../img/Kalle.png";

export class Introduction_NordicSongs extends React.Component<{}, {}> {
  render = () => {
    return (
      <>
        <div className="intro">
          <div className="intro__content">
            <p className="intro__about">
              This album has been recorded in{" "}
              <a target="_blank" rel="noreferrer" href="http://www.nilento.se">
                Nilento studio
              </a>{" "}
              during the years 2017-20 and it will be released during 2021.{" "}
              These play links are protected by a personal login via a web link
              that has been provided to you.
            </p>
            <div className="intro__participants">
              <h3>Participants:</h3>
              <div className="intro__participants-list">
                <div className="intro__participants-listitem">
                  <div className="intro__participants-logobox">
                    <img
                      src={singer}
                      alt="Singer"
                      title="Singer"
                      className="intro__participants-logo intro__participants-logo--singer"
                    />
                  </div>
                  <div className="intro__participants-name">
                    Tobias Fjälling (song)
                  </div>
                </div>
                <div className="intro__participants-listitem">
                  <div className="intro__participants-logobox">
                    <img
                      src={piano}
                      alt="Piano"
                      title="Piano"
                      className="intro__participants-logo intro__participants-logo--singer"
                    />
                  </div>
                  <div className="intro__participants-name">
                    Kalle Stenbäcken (piano)
                  </div>
                </div>
                <div className="intro__participants-listitem">
                  <div className="intro__participants-logobox">
                    <img
                      src={strings}
                      alt="Strings"
                      title="Strings"
                      className="intro__participants-logo intro__participants-logo--singer"
                    />
                  </div>
                  <div className="intro__participants-name">
                    Daphne Trio (strings on tracks 4, 13 och 15)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="intro__pictures">
            <div className="intro__picture intro__picture1">
              <img
                src={tobias}
                alt="Song: Tobias Fjälling"
                title="Song: Tobias Fjälling"
                className="intro__img intro__img1"
                // onMouseEnter={highlightTextElement(el,true)}
                // onMouseLeave={highlightTextElement(el,false)}
              />
            </div>
            <div className="intro__picture intro__picture2">
              <img
                src={kalle}
                alt="Piano: Kalle Stenbäcken"
                title="Piano: Kalle Stenbäcken"
                className="intro__img intro__img2"
                // onMouseEnter={highlightTextElement(el,true)}
                // onMouseLeave={highlightTextElement(el,false)}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
}
