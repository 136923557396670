export class LimitsController{
    private _durationInSeconds:number;
    private _limits:number[];
    private _currentIx:number=0;

    constructor(durationInSeconds:number){
        this._durationInSeconds = durationInSeconds;
        const create = this._create;
        this._limits = [
            create(5, false),
            create(10, false),
            create(25),
            create(50),
            create(90)
        ];
    }

    private _create = (time:number, isPercentage:boolean=true):number => {
        return isPercentage 
            ? Math.round((time / 100) * this._durationInSeconds) 
            : time
    }

    getNextLimitLevel = (time:number):number|null => {
        const {_currentIx, _limits} = this;
        if (time===0) return null; //no need to report in this case.
        if (_currentIx === _limits.length) return null; //we've already reported max and can quite now.
        const thisLimit = _limits[_currentIx];
        if (time>=thisLimit){
            this._currentIx++;
            return thisLimit;    
        }
        return null;
    }

    reset=():void=> {
        this._currentIx = 0;    
    }
}
