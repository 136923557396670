import React from "react";
import { AudioPlayerController } from "../../Controllers/AudioPlayerController";

export function minutesAndSeconds(seconds: number) {
  return (
    Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0")
  );
}

interface ICurrentTimeState {
  currentTimeInSeconds: number;
}
interface ICurrentTimeProps {
  audioPlayerController: AudioPlayerController;
}

export class CurrentTime extends React.Component<
  ICurrentTimeProps,
  ICurrentTimeState
> {
  constructor(props: ICurrentTimeProps) {
    super(props);
    this.state = {
      currentTimeInSeconds: 0,
    };
    props.audioPlayerController.Player.setAudioPlayerOnTimeUpdateDelegate(
      this.updateCurrentTime
    );
  }

  updateCurrentTime = (currentTimeInSeconds: number) => {
    this.setState({ currentTimeInSeconds });
  };

  render() {
    const currentTimeInSeconds = this.props.audioPlayerController.Player.isEnded()
      ? 0
      : this.state.currentTimeInSeconds;

    return (
      <div className="table-row__current-time">
        {minutesAndSeconds(currentTimeInSeconds)}
      </div>
    );
  }
}
