import { IHideInViewSettingsSubCategory } from "./ClientServerDTOs";
import { IdAndNameBase } from "./IdAndName";
import { ServerSpecfictSongDto, Song } from "./Song";
import { Lookup } from "./_Lookup";


export abstract class SongCategoryBase extends IdAndNameBase {
    public hideInViewSettings?: IHideInViewSettingsSubCategory;
    public description?: string;
    public categoryNestedLevel: number | undefined;

    public static CloneFrom(source: SongCategoryBase): SongCategoryBase {
        return { ...source };
    }
}

export class SongCategoryDto extends SongCategoryBase {
    public songList: ServerSpecfictSongDto[] = [];
    public childrenCategoryList?: SongCategoryDto[] = []
}


export class SongCategory extends SongCategoryBase {
    public songList: Song[] = [];
    public childrenCategoryList: SongCategory[] = []

    public static createUsing(songCategoryDto: SongCategoryDto, lookup: Lookup): SongCategory {
        const category = SongCategoryBase.CloneFrom(songCategoryDto) as SongCategory;
        category.songList = [];
        category.childrenCategoryList = [];

        songCategoryDto.songList.forEach(song => {
            category.songList?.push(Song.createUsing(song, lookup, category.id))
        })

        songCategoryDto.childrenCategoryList?.forEach(categoryDto => {
            category.childrenCategoryList.push(SongCategory.createUsing(categoryDto, lookup))
        })
        return category;
    }
}
