import React from "react";
import { AudioPlayerController } from "../../Controllers/AudioPlayerController";
import { CurrentTime, minutesAndSeconds } from "./CurrentTime";

export interface IProgressBarProps {
  audioPlayerController: AudioPlayerController;
  durationInSeconds: number;
  currentlyPlaying: boolean;
  visible: boolean;
  id: number;
}

export class ProgressBar extends React.Component<IProgressBarProps, {}> {
  _progressBarDiv: HTMLDivElement | null = null;
  _progressBarStopperDiv: HTMLDivElement | null = null;

  clickOnRange = (e: any) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position
    const pos = e.clientX;
    if (!this._progressBarDiv) throw Error("This should not be possible.");
    let rect = this._progressBarDiv.getBoundingClientRect();

    const percentage = ((pos - rect.left) / rect.width) * 100;
    this.props.audioPlayerController.callbackOnSeek(percentage);
  };

  stopperDragMouseDown = (e: any) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    document.onmouseup = this.closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = this.elementDrag;
  };

  elementDrag = (e: any) => {
    const { _progressBarStopperDiv } = this;

    if (!_progressBarStopperDiv || !_progressBarStopperDiv.parentElement)
      return;
    let rect = _progressBarStopperDiv.parentElement.getBoundingClientRect();
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    let posX = e.clientX;

    if (posX < rect.left) posX = rect.left;
    if (posX > rect.right - _progressBarStopperDiv.offsetWidth)
      posX = rect.right - _progressBarStopperDiv.offsetWidth;

    let percentage = ((e.clientX - rect.left) / rect.width) * 100;
    if (percentage < 0) percentage = 0;
    if (percentage > 100) percentage = 100;

    _progressBarStopperDiv.style.left = percentage + "%";

    this.props.audioPlayerController.callbackOnSeek(percentage);
  };

  closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  };

  render() {
    const {
      audioPlayerController,
      durationInSeconds,
      currentlyPlaying,
    } = this.props;

    const currentTimeInSeconds = audioPlayerController.Player.CurrentPlayTime;
    const timeLeft = durationInSeconds - currentTimeInSeconds;
    let percentage = (currentTimeInSeconds / durationInSeconds) * 100;
    if (this.props.audioPlayerController.Player.isEnded()) percentage = 0;
    const thumbWidth = this._progressBarStopperDiv
      ? this._progressBarStopperDiv?.offsetWidth
      : 0;

    let styleBar = {};
    let styleThumb = {};

    let currentTransition = "";
    // if (this._progressBarStopperDiv) {
    //   currentTransition = getComputedStyle(this._progressBarStopperDiv)
    //     .transition;
    //   if (currentTransition) currentTransition = `, ${currentTransition}`;
    //   console.log(currentTransition);
    // }
    if (this.props.visible && currentlyPlaying) {
      styleBar = {
        width: "100%",
        transition: `width ${timeLeft}s linear${currentTransition}`,
      };
      styleThumb = {
        left: `calc(100% - ${thumbWidth}px)`, // Thumb should not overflow on the right
        transition: `left ${timeLeft}s linear${currentTransition}`,
      };
    } else {
      styleBar = {
        width: `calc(${percentage}% + ${
          thumbWidth * (1 - percentage / 100) // Make sure end of progress bar is under thumb
        }px)`,
      };
      styleThumb = {
        left: `calc(${percentage}% - ${(thumbWidth * percentage) / 100}px`, // Thumb should not overflow on the right
      };
    }

    // if (this.props.visible) {
    //   console.log(styleBar, styleThumb);
    //   console.log(`currentTimeInSeconds: ${currentTimeInSeconds}
    //         durationInSeconds: ${durationInSeconds}`);
    // }

    let cl = "table-row__currently-playing-wrapper";
    if (!this.props.visible)
      cl = cl + " table-row__currently-playing-wrapper--invisible";

    return (
      <div className={cl}>
        <CurrentTime
          key={this.props.id}
          audioPlayerController={audioPlayerController}
        />
        <div className="table-row__progress-bar-wrapper">
          <div
            ref={(elem) => (this._progressBarDiv = elem)}
            className={"table-row__currently-playing"}
            onMouseDown={this.clickOnRange}
          />
          <div className={"table-row__progress-bar"} style={styleBar} key={1} />
          <div
            ref={(e) => (this._progressBarStopperDiv = e)}
            className="table-row__progress-bar--thumb"
            key={2}
            style={styleThumb}
            onMouseDown={this.stopperDragMouseDown}
          >
            <svg
              className="table-row__progress-bar--thumb-svg"
              width="7px"
              height="2.4rem"
              viewBox="0 0 70 240"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                strokeWidth="0"
                rx="35"
                id="svg_3"
                height="240"
                width="70"
                fill="rgba(75, 75, 75, 1)"
              />
            </svg>
          </div>
        </div>
        <div className="table-row__duration">
          {minutesAndSeconds(durationInSeconds)}
        </div>
      </div>
    );
  }
}
