import React from "react";
import kalle from "../../../../img/Kalle.png";

export class Introduction_Bach extends React.Component<{}, {}> {
  render() {
    return (
      <div className="intro">
        <div className="intro__content">
          <p>
            The Goldberg Variations performed by Kalle Stenbäcken (
            <a
              href="https://www.kallestenbacken.com/"
              target="_blank"
              rel="noreferrer"
            >
              more info
            </a>
            ). <br />
            These recordings are protected by a personal web link as provided to
            you.
            <br />
            <br />
          </p>
          <p className="intro__aboutBach">
            The Goldberg Variations, BWV 988, is a composition for piano by
            Johann Sebastian Bach consisting of an aria and a set of 30
            variations. It was first published in 1741, named after Johann
            Gottlieb Goldberg, who may also have been the first performer of the
            work.{" "}
            <a
              href="https://en.wikipedia.org/wiki/Goldberg_Variations"
              target="_blank"
              rel="noreferrer"
            >
              read more
            </a>
            <br />
            <br />
          </p>
        </div>
        <div className="intro__pictures intro__pictures--only-1">
          <div className="intro__picture intro__picture2">
            <img
              src={kalle}
              alt="Piano: Kalle Stenbäcken"
              title="Piano: Kalle Stenbäcken"
              className="intro__img intro__img2"
            />
          </div>
        </div>
      </div>
    );
  }
}
