import { IUpdateView } from "./UpdateableView";

export abstract class ViewControllerBase{
    _updateableViews:   IUpdateView[];
  
      constructor(updateableViews: IUpdateView[]) {
          this._updateableViews = updateableViews;
        } 
  
        protected _updateViews = () =>{
          this._updateableViews?.forEach(view => {
              view.updateView()
            });
      }
  } 