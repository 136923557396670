import { ICountry } from "./Country";
import { Person, PersonDto } from "./Person";

export class CountryByIdMap extends Map<Number | undefined, ICountry>{
    public getValue(id: number | undefined): ICountry {
        if (undefined) return {} as ICountry; //Better to return VoidCountry or throw an exception.
        return super.get(id) as ICountry;
    }
}

export class PersonByIdMap extends Map<Number | undefined, Person>{
    public getValue(id: number | undefined): Person {
        if (undefined) return {} as Person; //Better to return VoidCountry or throw an exception.
        return super.get(id) as Person;
    }
}

export class Lookup {
    public countryById: CountryByIdMap = new CountryByIdMap();
    public personById: PersonByIdMap = new PersonByIdMap();

    constructor(personList: PersonDto[], countryList: ICountry[]) {
        countryList.forEach(country => {
            this.countryById.set(country.id, country)
        });

        personList.forEach(person => {
            this.personById.set(person.id, Person.createUsing(person, this.countryById))
        });

    }
}