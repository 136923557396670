import React from "react";
import SwedishFlag from "../../../img/Swedish_flag.png";
import DanishFlag from "../../../img/Danish_flag.png";
import NorwegianFlag from "../../../img/Norwegian_flag.png";
import FinnishFlag from "../../../img/Finnish_flag.png";
import BritishFlag from "../../../img/British_flag.png";
import GermanFlag from "../../../img/German_flag.png";
import AmericanFlag from "../../../img/American_flag.png";

interface IFlagProps {
  countryId: number;
}

export class Flag extends React.Component<IFlagProps, {}> {
  private _flags = [
    //TODO: these hard coded settings are a bit unfortunate.
    //Suggestion solution: I think on client it might be better to have the Name mapping instead and do the lookup based on this. I.e. SE, GB, DK etc. It does require a bit of refactoring though.
    {
      flag: SwedishFlag,
      country: "Swedish",
    },
    {
      flag: DanishFlag,
      country: "Danish",
    },
    {
      flag: NorwegianFlag,
      country: "Norwegian",
    },
    {
      flag: FinnishFlag,
      country: "Finnish",
    },
    {
      flag: BritishFlag,
      country: "Brittish",
    },
    {
      flag: GermanFlag,
      country: "German",
    },
    {
      flag: AmericanFlag,
      country: "USA",
    },
  ];

  render() {
    const currentFlag = this._flags[this.props.countryId - 1]; //TODO: Ugly hack to make this work again. (it's an ID not, an index in the new structure)
    if (!currentFlag) {
      return;
    }
    return (
      <img
        src={currentFlag.flag}
        className="table-row__flag"
        alt={currentFlag.country}
        title={currentFlag.country}
      />
    );
  }
}
