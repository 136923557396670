import React from "react";
import "react-h5-audio-player/lib/styles.css";
import composerIcon from "../../../../img/composer-black.png";
import writerIcon from "../../../../img/writer-black.png";
import infoIcon from "../../../../img/info_icon2.png";
// import wikiIcon from "../../../../img/wiki-icon.png";
import { WebServerCommunicator } from "../../../../Services/WebServerCommunicator";
import { Flag } from "../Flag";
import { IProgressBarProps, ProgressBar } from "./ProgressBar1";
import { AudioPlayerProxy, IAudioPlayerProxyProps } from "./AudioPlayerProxy";
import {
  ICurrentPlayState,
  IPlayedSongSynchronizer,
} from "../../Controllers/PlayedSongSynchronizer";
import {
  AudioPlayerController,
  IAudioPlayerCallBacks,
} from "../../Controllers/AudioPlayerController";
import { UserSettings } from "../../Model/UserSettings";
import { IHideInViewSettingsSubCategory } from "../../Model/ClientServerDTOs";
import Collapse from "@kunukn/react-collapse";
import { Song } from "../../Model/Song";

interface IState {
  thisSongIsPlaying: boolean;
  currentTime: number;
  currentPlayState: ICurrentPlayState;
}

interface IProps {
  song: Song;
  playedSongSyncronizer: IPlayedSongSynchronizer;
}

export class Row extends React.Component<IProps, IState> {
  private _dataFetcher = WebServerCommunicator.Instance;
  private _audioPlayerController: AudioPlayerController;
  private _userSettings = UserSettings.Instance;

  constructor(props: IProps) {
    super(props);

    this.state = {
      thisSongIsPlaying: false,
      currentTime: 0,
      currentPlayState: {
        currentPlayOrder: null,
        isPlaying: false,
      } as ICurrentPlayState,
    };
    this._audioPlayerController = new AudioPlayerController(
      this._dataFetcher?.sessionAlivePing,
      props.song,
      props.playedSongSyncronizer,
      {
        updateRowViewOnPlayChange: this.updateViewOnPlayChange,
      } as IAudioPlayerCallBacks
    );
  }

  updateViewOnPlayChange = (isPlaying: boolean) => {
    setTimeout(() => {
      this.setState({ thisSongIsPlaying: isPlaying });
    }, 100); //NOTE: Without this delay, there is a race condition with the CSS animation in the ProgressBar.
  };

  async componentDidUpdate() {
    this._audioPlayerController.updatePlayingState();
  }

  render() {
    const { playedSongSyncronizer, song } = this.props;
    const { thisSongIsPlaying } = this.state;
    const currentPlayState: ICurrentPlayState = playedSongSyncronizer.getCurrentPlayState();
    const hideInView: IHideInViewSettingsSubCategory = this._userSettings.getUserSettingsForSubCategory(
        song.subCategoryId
    );

    const audioPlayerController = this._audioPlayerController;

    let cl1 = "table-row__info-icon",
      cl2 = cl1;
    if (!song.composer.infoUrl) cl1 = cl1 + " u-hidden";
    if (!song.lyricsAuthor?.infoUrl) cl2 = cl2 + " u-hidden";

    const iPlayVisible =
      currentPlayState.currentPlayOrder === song.sequentialPlayOrder;

    const propsInput = {
      audioPlayerProxy: {
        audioPlayerController,
        recordingId: song.id,
        recordingUrl: this._dataFetcher.getMp3UrlForRecording(song.id),
      } as IAudioPlayerProxyProps,

      progressBar: {
        key: song.id,
        id: song.id,
        visible: iPlayVisible,
        currentlyPlaying: thisSongIsPlaying,
        audioPlayerController,
        durationInSeconds: song.durationInSeconds,
      } as IProgressBarProps,
    };

    const songNumber = hideInView.songNumber ? "" : song.songNumber + " ";

    return (
        song && (
        <>
          <div className="table-row__wrapper">
            <div className="table-row">
              <div className="table-row__song">
                <div className="table-row__player">
                  <AudioPlayerProxy {...propsInput.audioPlayerProxy} />
                </div>
                <div className="table-row__songName">
                  {songNumber + song.name}
                </div>
              </div>
              <div className="table-row__metaData">
                {song.composer?.name && (
                  <div className="table-row__composer">
                    <img
                      src={composerIcon}
                      className="table-row__icon table-row__icon-left"
                      alt=""
                      title="Composer"
                    />
                    <div className="table-row__composer-name">
                      {song.composer?.name}
                    </div>
                    <a
                      className={cl1}
                      target="_blank"
                      rel="noreferrer"
                      href={song.composer.infoUrl}
                    >
                      <img
                        className="table-row__info-icon-img"
                        src={infoIcon}
                        // src={infoIcon}
                        alt={"More information on " + song.composer?.name}
                        title={
                          "More information on " + song.composer?.name
                        }
                      />
                    </a>
                    {!hideInView.flags && (
                      <div className="table-row__composer-country">
                        <Flag countryId={song.composer?.country.id} />
                      </div>
                    )}
                  </div>
                )}
                {song.lyricsAuthor?.name && (
                  <div className="table-row__lyricsAuthor">
                    <img
                      src={writerIcon}
                      className="table-row__icon table-row__icon-left"
                      alt=""
                      title="Writer"
                    ></img>
                    <div className="table-row__lyricsAuthor-name">
                      {song.lyricsAuthor?.name}
                    </div>
                    <a
                      className={cl2}
                      target="_blank"
                      rel="noreferrer"
                      href={song.lyricsAuthor?.infoUrl}
                    >
                      <img
                        className="table-row__info-icon-img"
                        src={infoIcon}
                        // src={infoIcon}
                        alt={
                          "More information on " + song.lyricsAuthor?.name
                        }
                        title={
                          "More information on " + song.lyricsAuthor?.name
                        }
                      />
                    </a>
                    <div className="table-row__lyricsAuthor-country">
                      <Flag countryId={song.lyricsAuthor?.country.id} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Collapse
              isOpen={iPlayVisible}
              className={"table-row__currently-playing-collapse-open"}
              overflowOnExpanded={true}
            >
              <ProgressBar {...propsInput.progressBar} />
              {song.description && (
                <div
                  className="table-row__description"
                  dangerouslySetInnerHTML={{
                    __html: song.description,
                  }}
                ></div>
              )}
            </Collapse>
          </div>
        </>
      )
    );
  }
}
