import { IPlayer } from "../Components/RowLevel/AudioPlayerProxy";
import { PlayStatus } from "../Model/IPlayState";
import { Song } from "../Model/Song";
import { LimitsController } from "./LimitsController";
import { IPlayedSongSynchronizer, ICurrentPlayState } from "./PlayedSongSynchronizer";

export interface IAudioPlayerCallBacks {
    updateRowViewOnPlayChange: (isPlaying: boolean) => void;
}

type SendAliveDelegate = (
    recordingId: number,
    time: number,
) => void;

export class AudioPlayerController {
    private _callBack: IAudioPlayerCallBacks;
    private _song: Song;
    private _sendAliveReportDelegate: SendAliveDelegate;
    private _player: IPlayer;
    private _playedSongSyncronizer: IPlayedSongSynchronizer;
    private _limitsController;

    constructor(
        reportListeningProgressDelegate: SendAliveDelegate,
        song: Song,
        playedSongSyncronizer: IPlayedSongSynchronizer,
        callbacks: IAudioPlayerCallBacks
    ) {
        this._sendAliveReportDelegate = reportListeningProgressDelegate;
        this._song = song;
        this._playedSongSyncronizer = playedSongSyncronizer;
        this._callBack = callbacks;
        this._player = {} as IPlayer;
        this._limitsController = new LimitsController(this._song.durationInSeconds);
    }


    public get Player(): IPlayer { return this._player; }
    public set Player(value: IPlayer) { this._player = value; } //NOTE: this should really only be set by Player itself. Unfortunately, this restriction is hard to achieve. 


    public playStateChanged = (playStatus: PlayStatus) => {
        const { _player, _limitsController } = this;

        if (!_player.exists()) return

        this.tryReport();
        if (playStatus === PlayStatus.end) _limitsController.reset();
        this._updateSongSynchAndViewPlayState(playStatus);
    };

    private _updateSongSynchAndViewPlayState = (playStatus: PlayStatus) => {
        const { _player, _song: _recording } = this;
        if (!_player.exists()) return

        this._playedSongSyncronizer.update(_recording.sequentialPlayOrder, playStatus);
        this._updateRowViewOnPlayChange(playStatus);

        // console.log(`onPlayPauseEnd called! recordingId: ${recordingId} isPlaying: ${isPlaying} playStatus: ${playStatus}`);
    };

    private _updateRowViewOnPlayChange = (playStatus: PlayStatus) =>
        this._callBack.updateRowViewOnPlayChange(playStatus === PlayStatus.play);


    callbackOnSeek = (percentage: number) => {
        const { _song: _recording, _playedSongSyncronizer, _player } = this;

        var currentSyncPlayState = _playedSongSyncronizer.getCurrentPlayState();
        const wasPlaying = currentSyncPlayState.isPlaying;

        this._updateRowViewOnPlayChange(PlayStatus.pause);
        _player.CurrentPlayTime = (percentage / 100) * _recording.durationInSeconds;
        if (wasPlaying) this._updateRowViewOnPlayChange(PlayStatus.play);

        this._callBack.updateRowViewOnPlayChange(wasPlaying);
    };

    tryReport = () => {
        const { _player, _limitsController, _song: _recording } = this;

        const toReport = _limitsController.getNextLimitLevel(_player.CurrentPlayTime);
        if (!toReport) return;

        this._sendAliveReportDelegate(_recording.id, toReport);
        //console.log(`Song: ${this._recording.name} - Limit reached: ${toReport}`);
    };

    updatePlayingState = async () => {
        const { _player, _playedSongSyncronizer, _song: _recording } = this;

        const currentSyncPlayState: ICurrentPlayState = _playedSongSyncronizer.getCurrentPlayState();
        if (!currentSyncPlayState.isPlaying) return;

        const isActiveSong = currentSyncPlayState.currentPlayOrder === _recording.sequentialPlayOrder

        if (_player.isPaused()) {
            if (isActiveSong) await _player.play()
        }
        else {
            if (!isActiveSong) _player.pause();
        }
    }
}
