export enum PlayStatus { //NOTE: we donät have to set a value here. But it simplifies debugging in Chrome when needed.
    play="play",
    pause="pause",
    end="ended",
}

export interface IPlayState {
    currentlyActive: number;
    currentlyPlaying: boolean;
    callbackPlayPauseEnd: (songId: number, status: PlayStatus) => void;
}
  
