import { Component } from "react";
import { Route } from "react-router";

import { SongListView } from "./Views/SongListView/SongListView";
import { LoginController } from "./Services/LoginController";

interface IProps {}

interface IState {
  loginTried: boolean | null;
}

export default class App extends Component<IProps, IState> {
  static displayName = App.name;

  constructor(props: IProps) {
    super(props);

    this.state = {
      loginTried: null,
    };
  }

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let userId = urlParams.get("user");
    if (!userId) userId = urlParams.get("session");
    const isTestlogin = urlParams.get("testlogin") === "true";
    (async () => {
      const loginTried = await LoginController.TryCreate(userId, isTestlogin);
      this.setState({ loginTried });
    })();
  };

  render() {
    return (
      this.state.loginTried && <Route exact path="/" component={SongListView} />
    );
  }
}
