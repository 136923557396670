import React from "react";

export interface IUpdateView {
  updateView: () => void;
}

export interface IUpdateState {}

export class UpdateableView<T, S extends IUpdateState>
  extends React.Component<T, S>
  implements IUpdateView {
  updateView = () => {
    this.setState({});
    //NOTE: we only need the state to be changed to that the render function is triggered and all rows are rerendered and updated according to currentPlayState.
    //However, The actual state is available in PlayedSongSynchronization. So we don't need to showel it down the component children.
  };
}
