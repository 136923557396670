import logo from "../../../img/listen.to.online_logo-white30.png";
import userIcon from "../../../img/usericon1-white.png";
import { LoginController } from "../../../Services/LoginController";

export function Header() {
  const user = LoginController.Instance.getLoggedInUser();

  return (
    <div className="head">
      <div className="head__logobox">
        <img src={logo} alt="listenTo.online logo" className="head__logo" />
      </div>
      {user && user.name && (
        <div className="head__userbox">
          <img src={userIcon} alt="" className="head__user-icon" />
          <div className="head__user">{user.name}</div>
        </div>
      )}
    </div>
  );
}

export function Footer() {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__copyright-notice">
          listenTo.online copyright &copy;{year}
        </div>
        <div className="footer__version">
          listenTo.online version 0.3
          {/* <a className="footer__release-notes" href="releasenotes.html">
            Release notes
          </a> */}
        </div>
        {/* <div className="footer__cookie-consent"></div> */}
      </div>
    </div>
  );
}
