
export interface IIdAndName {
    id: number;
    name: string;
}
export abstract class IdAndNameBase implements IIdAndName {
    public id: number = 0;
    public name: string = "";

    constructor(input: IdAndNameBase) {
        this.id = input.id;
        this.name = input.name;
    }
}
