import "../../index.css";
import { WebServerCommunicator } from "../../Services/WebServerCommunicator";
import { Subcategory } from "./Components/Subcategory";
import {
  IPlayedSongSynchronizer,
  PlayedSongSynchronizer,
} from "./Controllers/PlayedSongSynchronizer";
import {
  IUpdateState,
  UpdateableView,
} from "../../Infrastructure/UpdateableView";
import { LoginErrorMessage } from "../../Services/LoginController";
import React from "react";
import { Header, Footer } from "./Components/Header_Footer";
import { UserSettings } from "./Model/UserSettings";
import { SongCategory } from "./Model/SongCategory";
import { ISongInfoContainerDto, SongInfoContainer } from "./Model/ClientServerDTOs";

interface IState extends IUpdateState {
  songCategoryList:  SongCategory[] | null;
  playSynchronizer: IPlayedSongSynchronizer;
  failureMessage: string | null;
}

export class SongListView extends UpdateableView<{}, IState> {
  private _dataFetcher = WebServerCommunicator.Instance;
 
  constructor(props: any) {
    super(props);
    this.state = {
      songCategoryList: null,  
      playSynchronizer: {} as IPlayedSongSynchronizer,
      failureMessage: null,
    };
  }

  getRecordingData = () => {
    let failureMessage: string | null = null;
    this._dataFetcher
      ?.getRecordings()
      .then((result: ISongInfoContainerDto | null | LoginErrorMessage) => {
        if (!result)
          failureMessage =
            "There are no songs available for this login credential. Please contact the person who gave you the URL to this website.";
        else if (result instanceof LoginErrorMessage) {
          const loginFailure = result as LoginErrorMessage;
          failureMessage = loginFailure.getErrorMessage();
        } else {
          const dtoContainer = result as ISongInfoContainerDto;
          const container = new SongInfoContainer(dtoContainer);  
          const songCategoryList = container.songCategoryList;
          UserSettings.Instance.loadFromRecordingData(dtoContainer);
          const playSynchronizer = new PlayedSongSynchronizer(
            dtoContainer.nbrOfSongs,
            true,
            [this]
          );
          this.setState({ playSynchronizer, songCategoryList });
        }
        this.setState({ failureMessage });
      });
  };

  componentDidMount = () => {
    this.getRecordingData();
  };

  cont_play_checkboxHandler = () =>
    this.state.playSynchronizer?.toogleConsecutivePlay();

  render() {
    const { failureMessage, songCategoryList } = this.state;
    const dataExists = !!songCategoryList;
    return (
      <>
        {Header()}
        <div className="main">
          <div className="container">
            {failureMessage && <div>{failureMessage}</div>}
            {dataExists && (
              <>
                <div className="subcategories">
                  <div className="cont-play">
                    <label
                      htmlFor="cont-play-checkbox"
                      className="cont-play__checkbox-label"
                    >
                      Play audio files consecutively
                    </label>
                    <input
                      type="checkbox"
                      className="cont-play__checkbox"
                      id="cont-play-checkbox"
                      defaultChecked={true}
                      onClick={this.cont_play_checkboxHandler}
                    />
                  </div>
                </div>
              </>
            )}
            {dataExists &&
              songCategoryList?.map((songCategory, ix) => {
                return (
                  <Subcategory
                    songCategory={songCategory}
                    key={ix.toString()}
                    playedSongSyncronizer={this.state.playSynchronizer}
                  />
                );
              })}
          </div>
        </div>
        {Footer()}
      </>
    );
  }
}
