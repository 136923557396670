import { isLocalhost } from "../registerServiceWorker";
import { WebServerCommunicator } from "./WebServerCommunicator";

enum LoginErrorType {
    UserHasNotLoggedInYet = "In order to access this web site, you need to use the web link that was given to you with the login credentials.",
    UserIdIsMissing = "This web site is only accessible via an invitation provided to you. Please use that invitation link that was given to you.",
    LoginFailed = "The login attempt failed. Please contact the person who gave you the invitation URL.",
    UnknownCommunicationError = "An unknown communication error has occured. Please contact the system administrator if the problem is not a temporary network glitch.",
    UnknownError = "An unknown error has occured. Please contact the system administrator.", //TODO: ust be improved in the future.
  }
  
  const NO_USERID_PROVIDED: string = "_________NO_USERID_PROVIDED___";
  const CLIENT_TEST_USERID = "test";
  

export class LoginErrorMessage {
    private _type: LoginErrorType | null = LoginErrorType.UserHasNotLoggedInYet;
  
    public trySetUnknownCommunicationError = (httpStatusCode: number) => {
      if (httpStatusCode >= 400 && httpStatusCode < 600) {
        console.log("ERROR! Network or server problem in login!");
        this.setError(LoginErrorType.UnknownCommunicationError);
      }
    };
    public setLoginFailed = (userId: string | null) => {
      if (userId === NO_USERID_PROVIDED)
        this.setError(LoginErrorType.UserIdIsMissing);
      else this.setError(LoginErrorType.LoginFailed);
    };
    public setUnknownError = (error: string) => {
      console.log(error);
      this.setError(LoginErrorType.UnknownError);
    };
    private setError = (type: LoginErrorType) => (this._type = type);
    public setNoError = () => (this._type = null);
    public errorExists = () => this._type !== null;
  
    public getErrorMessage = () =>
      this._type ? this._type : LoginErrorType.UnknownError;
}

export interface User {
    id: string;
    name: string;
}
  
export class LoginController {
    
    private static _instance: LoginController;
    public static get Instance(): LoginController {
        return this._instance;
      }

    private _user:User|null = null;
  
    private _authentication: string | null = null;
    private _isLoggedIn: boolean | null = null;
    private _loginErrorMessage: LoginErrorMessage = new LoginErrorMessage();


    public static async TryCreate(userId: string|null, isTestLogin:boolean): Promise<boolean> {
        this._instance = new LoginController();
    
        if (!userId) userId = NO_USERID_PROVIDED;
        let isloggedIn = this._instance.isClientTestUser(userId) 
            || await this._instance.tryLogin(userId, isTestLogin);
        if (isloggedIn){
            WebServerCommunicator.Create(this._instance);
        }
        return isloggedIn;
      }
  
    public isClientTestUser = (userId:string|null=null) => {
        if (!userId &&  this._user) userId = this._user.id;
        if (isLocalhost && userId === CLIENT_TEST_USERID){
            this._user = {id:userId, name:"Local test only"} as User;
            return true;
        };
        return false;
    }
  
    public tryLogin = async (userId:string, isTestLogin: boolean): Promise<boolean> => {
      const testLogin = isTestLogin ? "true" : "";
      const fetchURL = `${WebServerCommunicator._baseUrl}Login/${userId}/${testLogin}`;
      const options = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(fetchURL, options)
        .then((response) => {
          this._loginErrorMessage.trySetUnknownCommunicationError(
            response.status
          );
          return response;
        })
        .then((response) => {
          return response.json();
        })
        .then((payload: any) => {
          this._isLoggedIn = payload.isLoggedIn;
          this._authentication = payload.authentication;
          this._user = payload.user;
  
          if (!this._isLoggedIn)
            this._loginErrorMessage.setLoginFailed(userId);
          else {
            this._loginErrorMessage.setNoError();
            //window.history.pushState({}, document.title, "/"); //Remote all parameters from Url. i.e. userId.
          }
        })
        .catch((error) => this._loginErrorMessage.setUnknownError(error));
  
      return this._isLoggedIn != null;
           
    };

    public getLoggedInUser = ():User|null => this._user; 
    public getLoggedInErrorMessage = () => this._loginErrorMessage;
  
    public getAuthentication = (): string | null => this._authentication;
  }