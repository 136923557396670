import { isLocalhost } from "../registerServiceWorker";
import { ISongInfoContainerDto } from "../Views/SongListView/Model/ClientServerDTOs";
import { LoginController, LoginErrorMessage } from "./LoginController";

enum HttpMethod {
    Get = "GET",
    Post = "POST",
    Put = "PUT",
    Delete = "DELETE",
}

export class WebServerCommunicator {
    private static _instance: WebServerCommunicator;
    public static get Instance(): WebServerCommunicator {
        return this._instance;
    }

    static _baseUrl: string = isLocalhost ? "http://localhost:5001/" : "./"; // MockAPI!
    private _loginController: LoginController | null = null;

    constructor(loginController: LoginController) {
        this._loginController = loginController;
    }

    public static Create(loginController: LoginController): void {
        this._instance = new WebServerCommunicator(loginController);
    }

    public async getRecordings(): Promise<
        ISongInfoContainerDto | LoginErrorMessage | null
    > {
        const loginController = this._loginController;
        if (!loginController) return null;
        if (loginController.isClientTestUser())
            return require("../SongInfoContainer.json"); //NOTE: a client test user can never listen to any songs.
        if (!loginController.getAuthentication())
            return loginController.getLoggedInErrorMessage();

        return this._fetchByLoggedInUser<ISongInfoContainerDto>(
            "Recordings",
            HttpMethod.Get
        );
    }

    public sessionAlivePing = (
        recordingId: number,
        time: number
    ): void => {
        var body = JSON.stringify({ recordingId, time });

        this._fetchByLoggedInUser("Recordings/SessionAlivePing", HttpMethod.Post, body)
            .then(() => { }); //NOTE: We don't care about the response here. 
    }

    public getMp3UrlForRecording(recordingId: number): string {
        let subPath = `Recordings/${recordingId}/${this._loginController?.getAuthentication()}`;
        // if (isLocalhost)
        //   subPath += "/" + this._loginController?.getAuthentication();
        return `${WebServerCommunicator._baseUrl}${subPath}`;
    }

    private async _fetchByLoggedInUser<T>(
        subPath: string,
        httpMethod: HttpMethod,
        body?: string
    ): Promise<T | null> {
        const fetchURL = WebServerCommunicator._baseUrl + subPath;
        const loginController = this._loginController;
        if (!loginController) return new Promise(() => null);

        const authentication = loginController.getAuthentication();
        if (!authentication) return new Promise(() => null);

        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set("Content-Type", "application/json");
        requestHeaders.set("authentication", authentication);

        const options: RequestInit = {
            method: httpMethod,
            headers: requestHeaders,
        };
        if (body) {
            options.body = body;
        }
        let result: T | null = null;

        const response = await fetch(fetchURL, options);
        if (!response) return null;
        result = await response.json();
        return result;
    }
}
