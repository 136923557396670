import { ICountry } from "./Country";
import { PersonDto } from "./Person";
import { SongCategoryDto, SongCategory } from "./SongCategory";
import { Lookup } from "./_Lookup";

export interface ISongInfoContainerDto {
    songCategoryList: SongCategoryDto[];
    personList: PersonDto[];
    countryList: ICountry[];
    nbrOfSongs: number;
}

export class SongInfoContainer {
    public songCategoryList: SongCategory[];

    constructor(songInfoContainer: ISongInfoContainerDto) {
        this.songCategoryList = [];

        var lookup = new Lookup(songInfoContainer.personList, songInfoContainer.countryList);

        songInfoContainer.songCategoryList.forEach(categoryDto => {
            this.songCategoryList.push(SongCategory.createUsing(categoryDto, lookup))
        });
    }
}

export interface IHideInViewSettingsSubCategory {
    songNumber: boolean;
    flags: boolean;
}
