import React from "react";
import { Introduction_Bach as IntroductionBach } from "./IntroductionBach";
import { Introduction_NordicSongs as IntroductionNordicSongs } from "./IntroductionNordicSongs";

interface IProps {
  subCategoryId: number;
}

export class IntroductionFactory extends React.Component<IProps, {}> {
  render() {
    const { subCategoryId } = this.props;

    const intro = {
      NordicSongs: subCategoryId === 1,
      Bach: subCategoryId === 5,
    };

    return (
      <>
        {intro.NordicSongs && <IntroductionNordicSongs />}
        {intro.Bach && <IntroductionBach />}
      </>
    );
  }
}
