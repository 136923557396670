import { IUpdateView } from "../../../Infrastructure/UpdateableView";
import { ViewControllerBase } from "../../../Infrastructure/ViewControllerBase";
import { PlayStatus } from "../Model/IPlayState";

export interface ICurrentPlayState {
  currentPlayOrder: number | null;
  isPlaying: boolean;
}

export interface IPlayedSongSynchronizer{
    toogleConsecutivePlay: () => void;
    update: (songIdsequentialPlayOrder: number, newPlayStatus: PlayStatus) => void;
    getCurrentPlayState: () => ICurrentPlayState;
} 

export class PlayedSongSynchronizer extends ViewControllerBase implements IPlayedSongSynchronizer {
  private _currentPlayState: ICurrentPlayState = {
    currentPlayOrder: null,
    isPlaying: false,
  };
  private _consecutivePlay: boolean;
  private _totalNoOfSongs: number;

  constructor(totalNoOfSongs: number=0, consecutivePlay = true, updateableViews: IUpdateView[]) {
    super(updateableViews);
    this._totalNoOfSongs = totalNoOfSongs;
    this._consecutivePlay = consecutivePlay;
  }

  public toogleConsecutivePlay = () => {
    this._consecutivePlay = !this._consecutivePlay;
  }

  public update = (newPlayOrder: number, newPlayStatus: PlayStatus) => {
    const current = this._currentPlayState;

    //set default assumptions:
    switch (newPlayStatus) {
        case PlayStatus.play:
          current.currentPlayOrder = newPlayOrder;
          current.isPlaying = true;
          break;
  
        case PlayStatus.pause:
            if (current.currentPlayOrder === newPlayOrder) { 
                current.isPlaying = false;
            }
          break;
  
        case PlayStatus.end:
          if (!current.currentPlayOrder) throw Error('should not be possible!');
                      
          const consecutivePlay = this._consecutivePlay && current.currentPlayOrder < this._totalNoOfSongs 
          if (consecutivePlay) {
            current.currentPlayOrder++; 
            current.isPlaying = true; 
          } else {
            current.isPlaying = false; 
          }
          break;
      }

    this._updateViews();
  };

  public getCurrentPlayState = (): ICurrentPlayState => this._currentPlayState;
}
