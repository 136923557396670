import { ICountry } from "./Country";
import { IdAndNameBase } from "./IdAndName";
import { CountryByIdMap } from "./_Lookup";

abstract class PersonBase extends IdAndNameBase {
    public infoUrl: string = "";

    public static CloneFrom(source: PersonBase): PersonBase {
        return { ...source };
    }
}

export class PersonDto extends PersonBase {
    public countryId: number = 0
}

export class Person extends PersonBase {
    public country: ICountry = {} as ICountry;

    public static createUsing(source: PersonDto, countryById: CountryByIdMap): Person {
        const person = PersonBase.CloneFrom(source) as Person;
        person.country = countryById.getValue(source.countryId);
        return person;
    }
}