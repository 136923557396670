//import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import reportWebVitals from "./reportWebVitals";

let baseUrl: string | undefined = document
  .getElementsByTagName("base")[0]
  .getAttribute("href")!;
const rootElement = document.getElementById("root");

ReactDOM.render(
  //   <React.StrictMode>
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  //</React.StrictMode>,
  rootElement
);

registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
