import React from "react";
import AudioPlayer from "react-h5-audio-player";
import { AudioPlayerController } from "../../Controllers/AudioPlayerController";
import { PlayStatus } from "../../Model/IPlayState";

export interface IAudioPlayerProxyProps {
  recordingId: number;
  recordingUrl: string;
  audioPlayerController: AudioPlayerController;
}

export interface IPlayer {
  exists: () => boolean;
  CurrentPlayTime: number;
  play: () => Promise<void>;
  pause: () => void;
  //   playerIsPaused: () => boolean;
  getPlayStatus: () => PlayStatus;
  isEnded: () => boolean;
  isPlaying: () => boolean;
  isPaused: () => boolean;
  setAudioPlayerOnTimeUpdateDelegate: (
    onTimeUpdateDelegate: (time: number) => void
  ) => void;
}

export class AudioPlayerProxy
  extends React.Component<IAudioPlayerProxyProps, {}>
  implements IPlayer {
  private _audioPlayerOnTimeUpdateDelegate: any;
  private _audioPlayer: HTMLAudioElement;
  private _playStatus: PlayStatus = PlayStatus.pause;

  constructor(props: IAudioPlayerProxyProps) {
    super(props);
    this._audioPlayer = {} as HTMLAudioElement;
    props.audioPlayerController.Player = this;
    //NOTE: We would have preferred from now audioPlayerController not to be accessible via props in this class, but don't think this is possible.
  }

  private setAudioPlayer = (audioPlayer: AudioPlayer | null) => {
    if (!audioPlayer || !audioPlayer.audio.current) {
      //Refactoring note: removed the possibility for this._audioPlayer to be null which is a simplification in this class (not having to null check it before usage).
      //The assumption is that when audioPlayer is null, it is sometimes, none of the other methods in this class will ever be called.
      //If this assumption is wrong (...), error will occur since the default object literal {} is not fullfilling the contract.
      return;
    }
    this._audioPlayer = audioPlayer.audio.current;
    this._audioPlayer.ontimeupdate = this.onTimeUpdate;
  };

  public exists = (): boolean => !!this._audioPlayer;
  public isEndedAndPaused = (): boolean =>
    this.exists() && this._audioPlayer.ended && this._audioPlayer.paused;

  public onTimeUpdate = () => {
    this._audioPlayerOnTimeUpdateDelegate(this.CurrentPlayTime);
  };

  public setAudioPlayerOnTimeUpdateDelegate(
    onTimeUpdateFunction: (time: number) => void
  ) {
    this._audioPlayerOnTimeUpdateDelegate = onTimeUpdateFunction;
  }

  public get CurrentPlayTime(): number {
    return this._audioPlayer.currentTime;
  }
  public set CurrentPlayTime(value: number) {
    this._audioPlayer.currentTime = value;
  }

  public async play(): Promise<void> {
    await this._audioPlayer.play();
  }

  public pause(): void {
    this._audioPlayer.pause();
  }

  public getPlayStatus = (): PlayStatus => {
    return this._playStatus;

    //NOTE: Below can unfortunately not be trused. When the events are thrown they state is not set correctly in the audioPlayer.
    // const { _audioPlayer } = this;
    // if (_audioPlayer.ended) return PlayStatus.end;
    // if (_audioPlayer.paused) return PlayStatus.pause;
    // return PlayStatus.play; //it's gotta be playing in this case.
  };

  public isEnded = (): boolean => this._playStatus === PlayStatus.end;
  public isPlaying = (): boolean => this._playStatus === PlayStatus.play;
  public isPaused = (): boolean => this._playStatus === PlayStatus.pause;

  private _playStateChanged = (e: Event): void => {
    if (e.type === "play") this._playStatus = PlayStatus.play;
    else if (e.type === "pause") this._playStatus = PlayStatus.pause;
    else if (e.type === "ended") this._playStatus = PlayStatus.end;
    else throw Error("Unknown AudioPlayer play state: " + e.type);

    this.props.audioPlayerController.playStateChanged(this._playStatus);
  };

  render() {
    const { recordingId, recordingUrl, audioPlayerController } = this.props;
    const { _playStateChanged } = this;

    return (
      <>
        <AudioPlayer
          key={recordingId}
          ref={(el) => this.setAudioPlayer(el)}
          className="table-row__player-audio"
          preload={"none"}
          listenInterval={1}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          showJumpControls={false}
          src={recordingUrl}
          onPlay={_playStateChanged}
          onPause={_playStateChanged}
          onListen={(e) => audioPlayerController.tryReport()}
          onEnded={_playStateChanged}
        />
      </>
    );
  }
}
