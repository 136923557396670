import React from "react";
import { SongTable } from "../Components/SongTable";
import { CollapseIcon } from "../Components/CollapseIcon";
import Collapse from "@kunukn/react-collapse";
import { IPlayedSongSynchronizer } from "../Controllers/PlayedSongSynchronizer";
import { IntroductionFactory } from "./Introductions/IntroductionFactory";
import { SongCategory } from "../Model/SongCategory";

interface IRowProps {
  songCategory: SongCategory;
  playedSongSyncronizer: IPlayedSongSynchronizer;
}

interface IRowState {
  accordeonOpen: boolean;
}

export class Subcategory extends React.Component<IRowProps, IRowState> {
  constructor(props: IRowProps) {
    super(props);
    this.state = {
      accordeonOpen: true,
    };
  }

  checkBoxHandler = () => {
    this.setState({ accordeonOpen: !this.state.accordeonOpen });
  };

  render() {
    const songCategory = this.props.songCategory;
    const expandableClassName = this.state.accordeonOpen
      ? ""
      : " subcategory--hidden";
    const collapseToggleId = "collapse-toggle" + songCategory.id.toString();
    // if (!subCategoryVM.description) subCategoryVM.description = "<br />"; //Hack to avoid more complex CSS solution.

    return (
      <>
        {songCategory && (
          <div
            className={
              "subcategory__container" + songCategory.categoryNestedLevel
            }
          >
            <div className="subcategory__container-line"></div>
            <div className="subcategory__title-container">
              <div className="subcategory__title-line"></div>
              <div
                className="subcategory__title"
                onClick={this.checkBoxHandler}
                id={collapseToggleId}
              >
                <CollapseIcon
                  onClick={this.checkBoxHandler}
                  open={this.state.accordeonOpen}
                />
                {songCategory.name}
              </div>
            </div>
            <Collapse
              isOpen={this.state.accordeonOpen}
              className={"subcategory" + expandableClassName}
              overflowOnExpanded={true}
            >
              <IntroductionFactory subCategoryId={songCategory.id} />
              {songCategory.description && (
                <div
                  className={
                    songCategory.songList.length === 0
                      ? "subcategory__description--shallow"
                      : "subcategory__description"
                  }
                  dangerouslySetInnerHTML={{
                    __html: songCategory.description,
                  }}
                ></div>
              )}

              {songCategory.songList.length !== 0 && (
                <SongTable
                  songList={songCategory.songList}
                  playedSongSyncronizer={this.props.playedSongSyncronizer}
                />
              )}

              {songCategory.childrenCategoryList &&
                songCategory.childrenCategoryList.map((childCategory) => {
                  return (
                    <Subcategory
                      songCategory={childCategory}
                      key={childCategory.id}
                      playedSongSyncronizer={this.props.playedSongSyncronizer}
                    />
                  );
                })}
            </Collapse>
          </div>
        )}
      </>
    );
  }
}
