import React from "react";

interface ICollapseIconProps {
  open: boolean;
  onClick: () => void;
}

interface ICollapseIconState {}

export class CollapseIcon extends React.Component<
  ICollapseIconProps,
  ICollapseIconState
> {
  render() {
    const { open, onClick } = this.props;
    let expandableClassName = "";
    if (open) expandableClassName = " collapse-icon-reverse";
    return (
      <svg
        onClick={onClick}
        viewBox="0 0 450 240"
        xmlns="http://www.w3.org/2000/svg"
        className={"collapse-icon" + expandableClassName}
      >
        <polygon points="0,0 450,0 225,240" stroke="0" />
      </svg>
    );
  }
}
