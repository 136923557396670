import { IHideInViewSettingsSubCategory, ISongInfoContainerDto } from "./ClientServerDTOs";

export class UserSettings {

    public static Instance = new UserSettings()

    private _hideInViewBySubCategoryId: Map<number, IHideInViewSettingsSubCategory> = new Map<number, IHideInViewSettingsSubCategory>();

    loadFromRecordingData = (container: ISongInfoContainerDto) => {
        container.songCategoryList.forEach(sc => {
            const hideInView = sc.hideInViewSettings ?? {} as IHideInViewSettingsSubCategory;
            this._hideInViewBySubCategoryId.set(sc.id, hideInView);
        })

    }

    getUserSettingsForSubCategory = (subCategoryId: number): IHideInViewSettingsSubCategory => {
        const hideInView = this._hideInViewBySubCategoryId.get(subCategoryId);
        if (!hideInView) return {} as IHideInViewSettingsSubCategory;
        return hideInView;
    }


}