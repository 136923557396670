import { IdAndNameBase } from "./IdAndName";
import { Person } from "./Person";
import { Lookup } from "./_Lookup";

export abstract class SongDto extends IdAndNameBase {
    public songNumber: number = 0;
    public description?: string;
    public sequentialPlayOrder: number = 0;
    public durationInSeconds: number = 0;
    public subCategoryId: number = 0;

    public static CloneFrom(source: SongDto): SongDto {
        return { ...source };
    }
}

export class ServerSpecfictSongDto extends SongDto {
    public composerId: number = 0;
    public lyricsAuthorId: number = 0;
}

export class Song extends SongDto {
    public composer: Person = {} as Person;
    public lyricsAuthor: Person = {} as Person;
    public categoryId: number = 0;

    public static createUsing(sourceSong: ServerSpecfictSongDto, lookup: Lookup, catetegoryId: number): Song {
        const song = SongDto.CloneFrom(sourceSong) as Song;
        song.composer = lookup.personById.getValue(sourceSong.composerId)
        song.lyricsAuthor = lookup.personById.getValue(sourceSong.lyricsAuthorId)
        song.categoryId = catetegoryId;
        return song;
    }
}