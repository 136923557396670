import React from "react";
import { Row } from "./RowLevel/Row";
import { IPlayedSongSynchronizer } from "../Controllers/PlayedSongSynchronizer";
import { Song } from "../Model/Song";

interface IState {}

interface IProps {
  songList: Song[];
  playedSongSyncronizer: IPlayedSongSynchronizer;
}

export class SongTable extends React.Component<IProps, IState> {
  render() {
    const { songList } = this.props;

    return (
      <div className="subcategory__songlist">
        {songList &&
          songList.map((song) => (
            <Row
              key={song.id}
              song={song}
              playedSongSyncronizer={this.props.playedSongSyncronizer}
            />
          ))}
      </div>
    );
  }
}
